import React from "react";

class About extends React.Component {
  render() {
    return (
      <div>
        <section className="ab_sec">
          <h2 className="ab_sec-h2">About Us</h2>
        </section>

        <section className="ab_sec2">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h3 className="ab_sec2-h3">Our Story</h3>
                <p className="ab_sec2-p">
                  GetProWriter began in 2021, prompted by a simple question: Why
                  is it so difficult to find and recruit expert content writers
                  with the required expertise? We focused only on content,
                  assisting companies all over the globe in creating content
                  that is genuinely brilliant – and we don’t just mean
                  “brilliant” in terms of quality; we mean “solid” in terms of
                  drawing attention, increasing traffic, and developing brands.
                </p>

                <p className="ab_sec2-p">
                  Our approach to content writing, on the other hand, hasn’t
                  changed. We match businesses with copywriters that understand
                  their industry inside and out. That means handling all of the
                  heavy lifting, such as vetting content writers and editors,
                  onboarding them, and managing them through consistent,
                  personalized contact.
                </p>

                <p className="ab_sec2-p">
                  We hope you’ll come along on the journey with us.
                </p>
              </div>

              <div className="col-md-6">
                <img
                  src="writer/img/ab_img.webp"
                  alt="ab_img"
                  className="ab_Sec2-img"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="ab_sec3">
          <div className="container">
            <div className="row">
              <div className="col-md-4 sec3-box">
                <i
                  aria-hidden="true"
                  className="fas fa-graduation-cap sec3-icon"
                ></i>
                <h2 className="sec3_box-h2">Our Mission</h2>
                <p className="sec3_box-p">
                  To become the greatest content production and distribution
                  platform in the world for all types of content producers and
                  companies. It won't be long until content becomes so important
                  to individuals and companies alike that there will be a need
                  for on-demand purchasing and selling of content. We want to be
                  the turning point!
                </p>
              </div>

              <div className="col-md-4 sec3-box2">
                <i aria-hidden="true" className="fas fa-walking sec3-icon2"></i>
                <h2 className="sec3_box-h2-1">What We Do</h2>
                <p className="sec3_box-p2">
                  Let’s face it — content creation is hard. We’re here to make
                  it easy. The days of hurling meaningless content against a
                  wall and hoping it sticks are over. Quality content is desired
                  by your audience, and Google expects it. That's where we can
                  help. Get Pro Writer Content Writing Services is meant to
                  create high-quality content on a large scale.
                </p>
              </div>

              <div className="col-md-4 sec3-box">
                <i
                  aria-hidden="true"
                  className="fas fa-user-shield sec3-icon"
                ></i>
                <h2 className="sec3_box-h2">Who we are</h2>
                <p className="sec3_box-p">
                  Russel Adams, GetProWriter's founder and CEO, set out to
                  create a platform that was simple but powerful. He hoped that
                  the platform would improve the content development process.
                  GPW was created from the beginning with the goal of making the
                  process easier for both clients and writers. So far, based on
                  feedback from clients and writers, we believe it is
                  succeeding.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="team_sec">
          <div className="container">
            <div className="row">
              <h2 className="team_sec-h2">Our Team</h2>
              <p className="team_sec-p">
                We are a rapidly expanding team of content writing specialists
                and thought leaders.
              </p>
              <div className="col-md-4">
                <div className="team_box">
                  <img
                    src="writer/img/Christopher-Smith.webp"
                    alt="description "
                    className="team_sec-img"
                  />
                  <h2 className="team_second-h2">CHRISTOPHER SMITH</h2>
                  <p className="team_second-p">DIRECTOR OF CONTENT WRITERS</p>
                  <p className="team_second-p2">
                    <i className="fa-brands fa-facebook f_f"></i>
                    <i className="fa-brands fa-instagram f_f"></i>
                    <i className="fa-brands fa-twitter f_f"></i>
                    <i className="fa-brands fa-pinterest-p f_f"></i>
                  </p>
                  <p className="team_second-p3">
                    Christopher works as the Director of Writers with GPWs
                    network of freelance writers and editors, providing
                    editorial and project support. He is in charge of writer
                    recruitment, engagement, and account hand-selection. Smith
                    may be found roaming around a bookshop or a park — or
                    binge-watching Parks and Recreation for the 35th time.
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="team_box">
                  <img
                    src="writer/img/Jack-Thomas.webp"
                    alt="Jack"
                    className="team_sec-img"
                  />
                  <h2 className="team_second-h2">JACK THOMAS</h2>
                  <p className="team_second-p">DIRECTOR OF CONTENT STRATEGY</p>
                  <p className="team_second-p2">
                    <i className="fa-brands fa-facebook f_f"></i>
                    <i className="fa-brands fa-instagram f_f"></i>
                    <i className="fa-brands fa-twitter f_f"></i>
                    <i className="fa-brands fa-pinterest-p f_f"></i>
                  </p>
                  <p className="team_second-p3">
                    Jack, as Director of Content Strategy, cultivates long-term
                    relationships with companies seeking to improve their SEO,
                    conversions, and content creation efficiency. He enjoys
                    working with our partners to develop and implement
                    goal-oriented content strategies. Derek enjoys going to a
                    concert or a comedy event on the weekends.
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="team_box">
                  <img
                    src="writer/img/Mira-Whedon.webp"
                    alt="Mira"
                    className="team_sec-img"
                  />
                  <h2 className="team_second-h2">MIRA WHEDON</h2>
                  <p className="team_second-p">CONTENT SUCCESS MANAGER</p>
                  <p className="team_second-p2">
                    <i className="fa-brands fa-facebook f_f"></i>
                    <i className="fa-brands fa-instagram f_f"></i>
                    <i className="fa-brands fa-twitter f_f"></i>
                    <i className="fa-brands fa-pinterest-p f_f"></i>
                  </p>
                  <p className="team_second-p3">
                    Mira serves as Content Success Manager at GPW, coordinating
                    all aspects of a la carte projects, directly overseeing the
                    network of freelance writers and editors, and recruiting new
                    talent. In his free time, Mira watches reality TV and
                    schemes about how to make it in today’s competitive writing
                    market.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default About;
