import React from "react";
import { Link } from "react-router-dom";

const AccountSettingSubscriptions = () => {
  return (
    <section className="reg_sec">
      <div className="container mt-3">
        <div className="row">
          <div className="col-lg-3">
            <h2 className="text-center">
              <img
                src="writer/img/logo/logo-img.png"
                alt="logo-img"
                className="as-img"
              />
            </h2>
            <br />

            <ul className="nav nav-pills flex-column acunt_dsh" role="tablist">
              <li className="nav-item">
                <Link className="nav-link " to="/dashboard">
                  Dasboard
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/accountsettingservices">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  data-bs-toggle="pill"
                  to="/accountsettingsubscriptions"
                >
                  Subscriptions
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/viewprofile">
                  Account Setting
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/accountsettingpaymentmethod">
                  Payment Methods
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/accountsettingbillinginfo">
                  Billing Information
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/transactionhistory">
                  Credits
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-9">
            <h5 className="user_tab1 mb-2">
              Welcome to <span className="user_ach">User</span>
            </h5>

            <div id="menu2" className="container tab-pane">
              <br />
              <h3>Menu 2</h3>
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccountSettingSubscriptions;
